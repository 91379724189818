import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F0EFEF',
      neutralLight2: '#E3DEDC',
      neutralLight1: '#B9B9B9',
      neutralDark2: '#5F5F64',
      neutralDark1: '#000000',
      primaryDark1: '#8BB3D5',
      primaryDark2: '#4F85B2',
      primaryDark3: '#3572A5',
      primaryDark4: '#265D8B',
      dangerLight: '#D13E60',
    },
  },
  fontFamily: {
    heading: "'Playfair Display', serif",
    paragraph: "'Be Vietnam Pro', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
